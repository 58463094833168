/* -- Team css
----------------------------------- -- */

.img-card {
    display: block;
    overflow: hidden;
    border-radius: 6px;
    margin: 15px auto;
    max-width: 40rem;
    border: 1px solid $grey-bg-color;
    transition: all .5s;

    .content {
        padding: 3rem 3rem 1.7rem;

        h4 {
            padding: 0;
            margin-bottom: 1.7rem;

            span {
                margin-top: 1.3rem;
            }
        }

        p {
            margin-bottom: 1.7rem;
        }
    }

    >img {
        width: 100%;
    }

    h4 {
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: $typo-lighter;
        font-weight: 300;
        padding: 3rem 15px 3rem;
        margin-bottom: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        span {
            display: block;
            font-size: 2.2rem;
            line-height: 2.8rem;
            font-weight: 700;
            color: $typo-dark;
            margin-bottom: 1.3rem;
            transition: all .3s;
        }
    }

    p {
        color: $typo-light;
    }

    &:hover {
        text-decoration: none;
        box-shadow: 0 5px 30px rgba(156, 185, 226, .3);

        h4 span {
            color: $color-palette-c;
        }
    }
}

.team-card {
    background-color: #fff;
}

.team-modal .modal-dialog {
    max-width: 42rem;
}

.portfolio-card {
    border: none;
}

.modal .modal-content {
    border: 1px solid $border-color;
    padding: 5rem 3rem 4rem;
    border-radius: 6px;

    .close {
        position: absolute;
        right: 2.5rem;
        top: 2.5rem;
        font-size: 2.4rem;
        color: $typo-light;
        z-index: 9;

        i {
            font-weight: 300;
        }

        &:hover {
            color: $color-palette-b;
        }
    }
}

.modal-title {
    h2 {
        span {
            display: block;
            font-size: 1.6rem;
            line-height: 2.6rem;
            font-weight: 300;
            color: $typo-light;
            margin-bottom: .7rem;
        }
    }

    .custom-btn {
        margin-top: .5rem;
        margin-bottom: 5rem;
    }
}

.team-modal .modal-body {
    padding: 0;

    img {
        max-width: 28rem;
        border-radius: 6px;
    }

    p {
        font-size: 1.6rem;
        line-height: 2.7rem;
    }

    h4 {
        margin: 2.2rem 0 1.8rem;
        padding-bottom: 1.8rem;
        border-bottom: 1px solid $border-color;

        span {
            display: block;
            font-size: 1.6rem;
            line-height: 2.6rem;
            color: $typo-light;
            font-weight: 300;
            margin-top: 1rem;
        }
    }

    .social-icons {
        max-width: 31rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        margin-top: 2.5rem;

        a {
            &:hover {
                color: $color-palette-c;
            }
        }
    }
}

.modal-header {
    border: none;
    padding: 0;
}

.modal-open .modal {
    background-color: $light-grey-bg-color;
    padding: 15px !important;
}