/* -- Reviews css
----------------------------- -- */
.customer-reviews{
    // background-image: url(../images/banner/inner-section-bg-left.png);
    background-position: left bottom;
    background-size: auto;
    background-repeat: no-repeat;
}
.review-card {
    max-width: 100%;
    padding: 4rem 3rem 2.4rem;
    .media {
        padding-bottom: 4rem;
        margin-bottom: 2.8rem;
        border-bottom: 1px solid $border-color;
        img {
            margin-right: 4rem;
            border-radius: 100%;
        }
        h5 {
            margin-bottom: .9rem;
        }
        p {
            margin-bottom: 0;
            font-size: 1.6rem;
            line-height: 2rem;
            font-style: normal;
            font-weight: 300;
        }
        a {
            text-decoration: none;
            &:hover {
                color: $color-palette-b;
            }
        }
    }
}