/* -- Features css
-------------------------------- -- */
.features {
    padding-bottom: .5rem;
}

.features-grid {
    padding-bottom: 9.5rem;

    .img-container {
        position: relative;
    }
}

.features-content {
    .section-title {
        margin-bottom: 2.5rem;
    }

    .feature-list {
        margin-top: 3rem;
    }

    .hyperlink {
        display: inline-block;
        margin-top: 1.9rem;
    }

    p {
        margin-bottom: 3rem;

        &:nth-last-of-type(1) {
            margin-bottom: .6rem;
        }
    }
}

.feature-video-popup {
    width: 100px;
    height: 100px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: $color-palette-c;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s;

    &::after {
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        margin-left: 4px;
        border-top: 10px solid transparent;
        border-left: 20px solid #fff;
        border-bottom: 10px solid transparent;
    }

    &:hover {
        box-shadow: 0 5px 20px rgba(156, 185, 226, 1);
    }
}

.feature-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
        position: relative;
        display: flex;
        align-items: flex-start;
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: $typo-lighter;
        font-weight: 500;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        i {
            font-weight: 400;
            color: $color-palette-c;
            line-height: 2.8rem;
            width: 2.5rem;
            margin-right: 2rem;
            text-align: center;
        }
    }
}