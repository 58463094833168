/* -- Banner css
---------------------------------- -- */

.banner {
    height: 575px;
    padding-top: 16.8rem;
    // background-image: url(../images/banner/inner-section-bg.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;

    h1 {
        margin: .3rem 0 2.5rem;
    }

    p {
        margin-bottom: 4rem;
    }

    .custom-btn {
        margin-bottom: 2rem;
    }

    img {
        padding-left: 8rem;
    }
}

.home-banner {
    height: 636px;
    padding-top: 8.8rem;
}

.breadcrumb-wrapper {
    position: absolute;
    top: 8.7rem;
    left: 0;
    width: 100%;

    @media screen and (max-width: 991px) {
        background-color: $border-color;
    }
}

.breadcrumb {
    background: transparent;
    padding: 5rem 0;
    margin: 0;
    color: $typo-lighter;

    li {
        font-family: $secondary-font;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 700;

        &:hover {
            color: $color-palette-b;
        }

        a {
            color: currentColor;
        }

        &.active {
            color: currentColor;
        }
    }

    @media screen and (max-width: 991px) {
        padding: 2rem 0;
    }
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-size: 1.2rem;
    margin: 0 .5rem;
    color: currentColor;
}