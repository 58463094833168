/* -- Browser Hacks
---------------------------------- -- */

/* -- Chrome hacks -- */

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {}

@media all and (min--moz-device-pixel-ratio:0) and (min-resolution: 3e1dpcm) and (max-width: 1199px) {}

/* -- Firefox hacks -- */

@media all and (min--moz-device-pixel-ratio:0) and (min-resolution: 3e1dpcm) {}

/* -- IE hacks -- */

@media screen and (min-width:0\0) {}