/* -- Projects css
----------------------------------- -- */

.projects {
    // background-image: url(../images/banner/inner-section-bg-right.png);
    background-position: right bottom;
    background-size: auto;
    background-repeat: no-repeat;
}

.tab-pane {
    width: 100% !important;
    position: relative;

    .col-slider-2 {
        width: 20%;
    }
}

.item-showcase {
    .custom-btn {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
    }
}

.owl-carousel {
    transition: height 1s !important;
}

.owl-carousel .owl-dots {
    text-align: center;
    display: none;
}

.owl-carousel {
    .owl-nav {

        .owl-prev,
        .owl-next {
            position: absolute;
            top: 50%;
            left: 8rem;
            opacity: 0;
        }

        .owl-next {
            left: auto;
            right: 8rem;
        }
    }

    &:hover {

        .owl-prev,
        .owl-next {
            opacity: 1;
        }
    }
}

.owl-carousel button.owl-dot {
    width: 10px;
    height: 10px;
    background: $color-palette-c;
    display: block;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    margin: 15px 5px;
    opacity: .5;

    &.active {
        opacity: 1;
    }
}

.owl-carousel {
    .owl-item {
        .item {
            padding: 0 2rem;
        }
    }

    .img-card {
        max-width: 100%;
    }
}

.projects-carousel {
    .owl-stage-outer {
        text-align: center;

        .owl-stage {
            margin: 0 auto;
            text-align: center;
            display: inline-flex;
            width: auto !important;
            overflow: hidden;
        }
    }
}

.btn-filter-wrap {
    margin-bottom: 3.5rem;
}

.modal {
    &.product-modal {
        .modal-img {
            padding-right: 5rem;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width: 991px) {
                padding: 5rem 5rem 0;
            }

            @media (max-width: 767px) {
                padding: 0;
            }
        }

        .modal-content {
            padding: 0;
        }

        .modal-body {
            padding: 0
        }

        .modal-body__inner-content {
            padding: 5rem;
            padding-left: 0;

            @media (max-width: 991px) {
                padding: 5rem;
            }

            @media (max-width: 767px) {
                padding: 3rem 1.5rem;
            }

            h4 {
                margin-bottom: 2rem;

                span {
                    display: block;
                    font-size: 1.6rem;
                    line-height: 2.7rem;
                    color: $typo-light;
                    font-weight: 300;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}