// Custom Variables


//Fonts
$primary-font: 'Roboto', sans-serif;
$secondary-font: 'Catamaran', sans-serif;


//Theme colors
$color-palette-a: #03cde9;
$color-palette-b: #fc7c56;
$color-palette-c: #0a8aff;
$color-palette-d: #6384ff;
$border-color: #e6f2ff;

//Typo colors
$typo-dark: #32325c;
$typo-light: #546182;
$typo-lighter: #888FB8;
$typo-extra-light: #DDDFEB;

//Others
$dark-grey-bg-color: #32325c;
$grey-bg-color: #e2ebff;
$light-grey-bg-color: #f9fcff;