/* -- Pricing style
---------------------------------- -- */

.pricing {
    // background-image: url(../images/banner/inner-section-bg.png);
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.pricing-grid-wrapper {
    [class*="col-"]:first-child {
        .pricing-group {
            margin-right: -2px;
        }
    }
}

.pricing-group {
    border: 2px solid $border-color;
    border-radius: 6px;
}

.group-title {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $typo-dark;
    font-weight: 700;
    padding: 1.3rem 15px .9rem;
    background-color: #f1f8ff;
    border-bottom: 2px solid $border-color;
}

.pricing-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    background-color: #fff;
    border-right: 2px solid $border-color;
    padding: 0 0 3rem;

    &.active {
        .price {
            color: $color-palette-c;
        }

        .custom-btn {
            opacity: 1;
        }
    }

    .facilities {
        flex: 0 0 100%;
        padding-left: 4rem;
        padding-right: 4rem;
        padding-bottom: 0;
    }

    .custom-btn {
        transition: all 1s;
    }

    &:hover {
        .custom-btn {
            opacity: 1;
        }
    }
}

.pricing-group {
    margin: 15px 0;

    [class*="col-"]:nth-last-of-type(1) {
        .pricing-block {
            border: none;
        }
    }
}

.pricing-level {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 4rem 15px 1rem;
    margin-bottom: 0;
}

.price {
    font-size: 10rem;
    line-height: 1;
    font-weight: 400;
    color: $color-palette-b;
    position: relative;
    display: inline-block;
    margin: 2rem auto;

    sup,
    sub {
        font-size: 2.4rem;
        line-height: 3.6rem;
        position: absolute;
    }

    sup {
        top: .6rem;
        left: -1.3rem;
    }

    sub {
        bottom: .6rem;
        right: -4rem;
    }
}