/* -- Header css
---------------------------------- -- */

.page-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(255, 255, 255, 1);
    transition: background-color .5s;

    &.scrolled {
        background-color: transparent;
        .navbar {
            background-color: rgba(255, 255, 255, .97);
            padding: 0 3rem;
            margin-top: 3rem;
            border-radius: 6px;
            box-shadow: 0 5px 30px rgba(156, 185, 226, .3);
        }
    }

    .navbar-brand {
        img {
            height: 3rem;
        }
    }
}

.navbar {
    border-bottom: 1px solid #ecf2f8;
    padding: 0;
    transition: all .5s;
}

.navbar-brand {
    padding: 0;
    line-height: 8.6rem;
}

.navbar-nav {
    li {
        position: relative;
        margin-left: 1.5rem;

        a:not(.custom-btn) {
            display: inline-block;
            font-size: 1.6rem;
            line-height: 2rem;
            color: $typo-light;
            padding: 3.3rem .5rem;
            font-weight: 400;
            text-decoration: none;
            position: relative;
            word-break: initial;
            white-space: nowrap;
            transition: all .5s;

            &::before {
                content: " ";
                position: absolute;
                bottom: -1px;
                left: 0;
                display: block;
                width: 0;
                height: .2rem;
                background-color: $color-palette-b;
                transition: width .5s;
            }

            &:hover,
            &.active {
                &::before {
                    width: 100%;
                }
            }
        }

        &.has-dropdown {

            >a {
                position: relative;

                &::after {
                    content: "\f078";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 1.2rem;
                    font-weight: 300;
                    margin-left: .5rem;
                }
            }

            &:hover {
                .submenu {
                    @media (min-width: 992px) {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .custom-btn {
        font-size: 1.4rem;
        margin-left: 1rem;
    }

    .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        padding: 1.5rem 0;
        border: 1px solid $border-color;
        min-width: 30rem;
        visibility: hidden;
        opacity: 0;

        @media screen and (min-width: 992px) {
            transition: all .3s;
        }

        @media screen and (max-width: 992px) {
            display: none;
        }

        li {
            list-style: none;
            margin: 0;

            a {
                padding: 1rem 3rem;

                &::before {
                    display: none;
                }

                &:hover,
                &.active {
                    color: $color-palette-b;
                }
            }
        }

        @media (max-width: 992px) {
            position: static;
            visibility: visible;
            opacity: 1;
        }
    }
}

.navbar-toggler {
    margin: 0;
    padding: 0;
    border: none;
    outline: 0;
}

.custom-toggler-icon {
    display: block;
    width: 3rem;
    height: .2rem;
    background-color: $color-palette-b;
    margin: .5rem 0;
    transition: all .5s;
}

.navbar button.change .custom-toggler-icon:nth-of-type(1) {
    transform: rotate(-45deg) translate(-7px, 5px);
}

.navbar button.change .custom-toggler-icon:nth-of-type(2) {
    opacity: 0;
}

.navbar button.change .custom-toggler-icon:nth-of-type(3) {
    transform: rotate(45deg) translate(-5px, -3px);
}