/*-- Comments --*/

.comment-wrapper {
    padding-top: 7rem;

    @media screen and (max-width: 991px) {
        padding-top: 1rem;
    }
}

.comment-box {
    padding-bottom: 3rem;
}

.comment-list {
    padding-left: 0;
    margin-bottom: 3rem;
    list-style: none;

    .comment-list {
        margin-top: 3rem;
        padding-left: 5rem;

        @media screen and (max-width: 991px) {
            padding-left: 2rem;
        }
    }
}

.comment {
    margin-bottom: 3rem;

    .comment-inner {
        display: flex;
        align-items: flex-start;
        padding: 3rem;
        border-radius: 6px;
        border: 1px solid $grey-bg-color;
    }

    img {
        max-width: 12rem;
        margin-right: 3rem;
        border-radius: 100%;

        @media screen and (max-width: 991px) {
            max-width: 5rem;
            margin-right: 1.5rem;
        }
    }

    h5 {
        margin-bottom: .7rem;
    }

    p {
        font-size: 1.6rem;
        line-height: 2.7rem;
        color: $typo-light;
    }

    .comment-meta-date {
        font-size: 1.2rem;
        line-height: 2.4rem;
        color: $typo-lighter;
        font-weight: 400;
        margin-left: 1.6rem;
        vertical-align: 1px;
    }

    .comment-reply-btn {
        a {
            font-family: $secondary-font;
            font-size: 1.6rem;
            line-height: 2rem;
            color: $color-palette-b;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: 6px;

            &:hover {
                color: $color-palette-c;
            }
        }
    }
}

.comment-respond {
    padding-top: 7rem;

    @media screen and (max-width: 991px) {
        padding-top: 2rem;
    }
}