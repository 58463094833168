/* -- Widgets css -- */
.widget-title {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;
}

.widgets-list {
    list-style: none;
    padding-left: 0;
}

.widget {
    margin-bottom: 50px;

    ul {
        font-size: 1.6rem;
        line-height: 2.7rem;
        color: $typo-light;
    }

    li {
        margin-bottom: 1.5rem;
    }

    a {
        color: inherit;

        &:hover {
            color: $color-palette-b;
        }
    }

    .media {
        align-items: center;
        margin-bottom: 3rem;

        a {
            margin-right: 1rem;
        }

        img {
            width: 100%;
            max-width: 5rem;
            max-height: 5rem;
            object-fit: cover;
            border-radius: 6px;
        }

        h5,h6 {
            margin-bottom: .5rem;
        }
    }
}