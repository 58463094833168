/* -- News details css
--------------------------------- -- */
.post-details {
    padding-top: 10rem;
    padding-bottom: 5rem;

    @media screen and (max-width: 991px) {
        padding-top: 6rem;
        padding-bottom: 0;
    }
}

.post-sidebar {
    @media screen and (max-width: 991px) {
        padding-top: 3rem;
    }

    @media screen and (min-width: 992px) {
        padding-left: 2rem;
    }
}

.article-details {
    p {
        margin-bottom: 3rem;
    }

    blockquote,
    .blockquote {
        position: relative;

        p {
            margin-bottom: 0;
        }

        &::before {
            content: "“";
            position: absolute;
            top: -1.5rem;
            left: 5.5rem;
            font-family: $secondary-font;
            font-size: 20rem;
            line-height: 1;
            color: rgba(84, 97, 130, .05);
        }
    }
}

figure {
    img {
        border-radius: 6px;
    }
}