/* -- Services css
-------------------------------- -- */

.services {
    padding: 0 14.0625%;

    @media screen and (max-width: 767px) {
        padding: 0 5%;
    }

    .floating-service-wrapper {
        transform: translateY(-95px);
        background: #fff;
        box-shadow: 0 5px 50px rgba(156, 185, 226, 0.2);
        border-radius: 6px;
        padding-bottom: 5.5rem;
    }
}

.facilities {
    padding: 1.5rem 3rem 6rem;

    >div {
        display: flex;
        align-items: flex-start;
        font-family: $secondary-font;
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: #888fb8;
        font-weight: 700;
        margin-bottom: 1.1rem;
        position: relative;

        i {
            font-size: 1.4rem;
            line-height: 2.6rem;
            color: $color-palette-c;
            font-weight: 400;
            margin-right: 1.6rem;
            width: 1.8rem;
            text-align: center;
        }
    }
}

.service-block {
    position: relative;
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 4.5rem;
    padding: 0 15px;

    >img {
        margin: 0 1rem 3.6rem;
        max-height: 9rem;
    }

    h4,.h4 {
        margin-bottom: 1.8rem;
        transition: all .5s;
    }

    p {
        color: $typo-light;
    }

    .service-overlay {
        position: absolute;
        top: -15px;
        right: 15px;
        min-height: calc(100% + 15px);
        left: 15px;
        border: 2px solid $border-color;
        background: #fff;
        padding: 3rem 0;
        visibility: visible;
        opacity: 1;
        transition: all .5s;

        .facilities {
            padding-bottom: 1.5rem;
            img {
                margin-top: .6rem;
                margin-right: 2rem;
            }
        }
    }

    &:hover {
        h4 {
            color: $color-palette-c;
        }
        .service-overlay {
            opacity: 1;
            visibility: visible;
        }
    }
}