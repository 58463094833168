/*-- Process --*/

.process-tab-container {
    max-width: 92rem;
    margin: 0 auto;

    .nav-pills {
        margin-top: 1.5rem;
        margin-left: -1rem;
        margin-right: -1rem;
        justify-content: center;

        @media screen and (min-width: 992px) {
            flex-direction: column;
        }
    }

    .nav-link {
        position: relative;
        display: flex;
        align-items: flex-start;
        max-width: 32rem;
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 700;
        color: $typo-lighter;
        padding: 4rem 6rem 4rem 3rem;
        background: #fff;
        border-radius: 6px;
        margin-bottom: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        box-shadow: 0 4px 10px 0 rgba(221, 223, 235, 0.1);

        @media screen and (max-width: 991px) {
            font-size: 1.4rem;
            padding: 1rem 4rem 1rem 1rem;
        }

        span {
            margin-right: 2rem;
            color: $typo-extra-light;
            white-space: nowrap;
            transition: all .5s;

            @media screen and (max-width: 991px) {
                margin-right: .5rem;
            }
        }

        i {
            position: absolute;
            right: 6rem;
            top: 4.2rem;
            font-size: 2rem;
            line-height: 2.1rem;
            font-weight: 400;
            color: $color-palette-b;
            margin-left: auto;
            opacity: 0;
            transition: all .5s;

            @media screen and (max-width: 991px) {
                top: 1.3rem;
                right: 2rem;
                font-size: 1.4rem;
            }
        }

        &.active,
        &:hover {
            background: #fff;
            color: $typo-dark;
            box-shadow: 0 20px 30px 5px rgba(136,143,184,0.12);

            span {
                color: $color-palette-b;
            }

            i {
                opacity: 1;
                right: 3rem;

                @media screen and (max-width: 991px) {
                    right: 1rem;
                }
            }
        }
    }

    .tab-content {
        padding-left: 2.4rem;

        @media screen and (max-width: 991px) {
            padding-left: 0;
            text-align: center;
        }
    }
}

.process-card {
    max-width: 100%;
    background: #fff;
}

.process-card-content {
    padding: 3rem 3rem 1rem;

    h3 {
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 2rem;
    }
}