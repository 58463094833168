/* -- Newsletter style
------------------------------ -- */

.newsletter-form {
    max-width: 67rem;
    margin: 0 auto;
    padding: 2.5rem 0 1.5rem;

    // .row {
    //     margin-left: -.5rem;
    //     margin-right: -.5rem;

    //     [class*="col-"] {
    //         padding-left: .5rem;
    //         padding-right: .5rem;
    //     }
    // }

    input {
        box-shadow: 0 20px 30px 0 rgba(156, 185, 226, 0.1);

        &:hover,
        &:focus {
            border-color: $typo-lighter;
        }
    }

    .custom-btn {
        width: 100%;
        padding: 1.5rem 3rem 1.4rem;
    }
}

.social-icons-wrapper {
    p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: #9cb9e2;
        font-weight: 700;
        margin: 1.5rem 0 1rem;
    }
}

.social-icons {
    padding-left: 2rem;
    margin: 1.5rem 0 .8rem;

    li {
        display: inline-block;
        margin-right: 1.5rem;
        color: #9cb9e2;
        transition: all .5s;

        a {
            font-size: 1.8rem;
            color: currentColor;
            transition: none;
        }

        &:hover {
            color: #fff;
        }
    }
}

.newsletter__white {
    background: $light-grey-bg-color;
    border-top: 1px solid $grey-bg-color;

    h2 {
        color: $typo-dark;
    }

    .social-icons {
        li {
            &:hover {
                color: $color-palette-c;
            }
        }
    }
}