/* -- News css
--------------------------------- -- */

.news-card {
    background-color: #fff;
    .read-more {
        font-size: 1.6rem;
        line-height: 3rem;
        color: $typo-light;
        font-weight: 700;
        max-width: calc(100% - 6rem);
        margin: 0 auto;
        padding: 2rem 0 1.8rem;
        border-top: 1px solid $border-color;
        transition: all .3s;
    }

    &:hover {
        .read-more {
            color: $color-palette-c;
        }
    }

    &:focus {
        border: 2px solid $border-color;
    }
}

.full-width-modal {
    .modal-dialog {
        max-width: 1170px;
    }

    .modal-feat-img {
        margin-bottom: 3.9rem;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 1.8rem;
    }

    .modal-content {
        padding-top: 3.5rem;
        padding-bottom: 1.7rem;
    }

    p {
        margin-bottom: 2.3rem;
    }
}

.blog-info {
    list-style: none;
    padding-left: 0;
    margin-bottom: 4.2rem;
    text-align: center;

    li {
        display: inline-block;
    }

    li,
    a {
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: $typo-light;
        text-decoration: none;
        font-weight: 300;

        &:hover {
            color: $color-palette-b;
        }

        img {
            vertical-align: -1px;
            margin-right: 1rem;
        }
    }

    li:not(:last-child) {
        margin-right: 2.5rem;
    }
}