/* -- Location css
----------------------------- -- */

.location {
    // background-image: url(../images/banner/inner-section-bg-right.png);
    background-position: right bottom;
    background-size: auto;
    background-repeat: no-repeat;
}

.nav-tabs {
    padding-top: 1.6rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-color;
    .nav-item {
        margin-bottom: 0;
    }
    .nav-link {
        font-family: $secondary-font;
        color: $color-palette-c;
        font-weight: 700;
        background: transparent;
        border: none;
        padding: 2rem .5rem 3.1rem;
        margin: 0 1.5rem;
        position: relative;
        cursor: pointer;
        i{
            vertical-align: -2px;
            margin-right: .8rem;
        }
        &::after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            display: block;
            background-color: $color-palette-b;
            transition: all .5s;
        }
        &:hover,
        &.active,
        &.btn-active,
        &.is-checked {
            color: $color-palette-b;
            background: transparent;
            &::after {
                width: 100%;
            }
        }
    }
}

.location-tab-content {
    margin-bottom: 1.5rem;
    .tab-pane {
        border-radius: 6px;
        overflow: hidden;
        height: 450px;
        margin-top: 1.5rem;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
}

.contact-info {
    .info {
        margin-top: 1.5rem;
    }
    padding-top: 2.4rem;
    a:not(.custom-btn) {
        font-size: 1.8rem;
        color: $typo-light;
        font-weight: 300;
        text-decoration: none;
        margin-bottom: .6rem;
        display: block;
        &:hover {
            color: $color-palette-b;
        }
    }
    .custom-btn {
        margin-bottom: 1.5rem;
    }
}

.contact-form-wrapper {
    margin: 0 auto;
    max-width: 80rem;
}