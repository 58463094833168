/* -- Clients css
---------------------------------------- -- */

.clients {
    padding: 12.5rem 0;
}

.client {
    text-align: center;
    outline: 0;
    border: none;
    img {
        display: inline-block;
        opacity: .4;
        transition: all .3s;
    }
    &:hover {
        img {
            opacity: 1;
        }
    }
}

.clients-carousel-wrapper {
    margin: 0 auto;
    .owl-stage {
        display: flex;
        align-items: center;
        .item {
            img {
                width: auto;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
    .owl-dots{
        display: none;
    }

    .owl-nav {
        display: none;
    }
}